<template>
  <div class="example_list">
    <Introduce :moduleId="3"/>
    <div class="example_box js_example_list">
      <p class="example_title">客户案例</p>
      <ul class="example_ul" v-if="list.length>0">
        <li class="example_every" v-for="item in list" :key="item.moduleId">
          <a :href="`/example/details?id=${item.moduleId}&moduleType=${modules.moduleType}`" target="_blank"
             class="exa_link vishide animate__animated">
            <div class="exa_img_box">
              <img class="exa_img" :src="utils.setOssUrl(item.picture)" alt="">
            </div>
            <div class="exa_pad_right">
              <p class="exa_title">{{ item.title }}</p>
              <p class="exa_desc">{{ item.digest }}</p>
              <p class="exa_detail">
                <dj-button>了解详情</dj-button>
              </p>
            </div>
          </a>
        </li>
      </ul>
      <Nothing v-else class="nothing"/>
    </div>
    <dj-paging :pageNumber="page.pageNo" :pageSize="page.pageSize" :total="page.total" :top="top"
               @current-change="handleCurrentChange"/>
  </div>
</template>

<script>
import {getCaseList} from "@/api/product.js";
import module from "@/const/module";
import $ from "jquery";
import utils from "@/utils/utils";
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";

export default {
  mixins: [metaInfoMixins],
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 9,
        total: 0
      },
      list: [],
      modules: module.example,
      top: 0,
      utils
    };
  },
  created() {
    this.getCaseListFun();
  },
  mounted() {
    this.getListTop();
    this.$nextTick(() => {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
      window.onresize = () => {
        this.removeVishide();
      };
    });
  },
  methods: {
    getListTop() {
      this.$nextTick(() => {
        const el = $(".js_example_list");
        let top = el.find(".example_title").offset().top;
        this.top = top;
        window.onresize = () => {
          let top = el.find(".example_title").offset().top;
          this.top = top;
        };
      });
    },
    handleCurrentChange(page) {
      this.page.pageNo = page;
      this.getCaseListFun();
    },
    getCaseListFun() {
      let params = {
        configType: this.modules.moduleType,
        ...this.page
      };
      getCaseList(params).then((res) => {
        if (res.success) {
          this.list = res.data.caseList;
          this.page.total = res.data.count;
          this.$nextTick(() => {
            this.handleScroll();
            this.removeVishide();
          });
        }
      });
    },
    handleScroll() {
      utils.handleScroll($(".js_example_list .example_every"), (i, el) => {
        setTimeout(() => {
          el.children(".exa_link").removeClass("vishide").addClass("animate__slideInUp").removeClass("animate__slideOutDown");
        }, i * 100);
      }, (i, el) => {
        setTimeout(() => {
          el.children(".exa_link").removeClass("animate__slideInUp").addClass("animate__slideOutDown");
        }, i * 100);
      });
    },
    removeVishide() {
      let w = $(window).width();
      if (w < 960) {
        $(".js_example_list .exa_link").removeClass("vishide");
      } else {
        $(".js_example_list .exa_link").addClass("vishide");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.example_list {
  .example_box {
    padding: 0 60px;
    .example_title {
      font-size: 40px;
      color: #333333;
      line-height: 56px;
      margin: 60px 0;
      text-align: center;
      font-weight: bold;
    }
    .example_ul {
      .example_every {
        margin-top: 24px;
        width: 100%;
        &:first-child {
          margin-top: 0;
        }
        .exa_link {
          display: inline-block;
          width: 100%;
          height: 100%;
          background: #FFFFFF;
        }
        .exa_img {
          width: 100%;
          height: 354px;
        }
        .exa_title {
          font-size: 26px;
          color: #333333;
          line-height: 37px;
          padding: 32px 32px 16px 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .exa_desc {
          font-size: 23px;
          color: #666666;
          line-height: 32px;
          padding: 0 32px;
          max-height: 96px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        .exa_detail {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px 0 34px 0;
        }
      }
    }
    .nothing {
      padding-bottom: 60px;
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .example_list {
    .example_box {
      padding: 0 60px;
      .example_title {
        font-size: 32px;
        color: #333333;
        line-height: 45px;
        margin: 60px 0 40px 0;
        text-align: center;
        font-weight: bold;
      }
      .example_ul {
        .example_every {
          margin-top: 24px;
          width: 100%;
          &:first-child {
            margin-top: 0;
          }
          .exa_link {
            display: flex;
            width: 100%;
            height: 100%;
          }
          .exa_img {
            width: 336px;
            height: 188px;
          }
          .exa_pad_right {
            min-width: 0;
            flex: auto;
          }
          .exa_title {
            font-size: 18px;
            color: #333333;
            line-height: 25px;
            padding: 24px 24px 8px 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .exa_desc {
            font-size: 16px;
            color: #666666;
            line-height: 22px;
            padding: 0 24px;
            height: 66px;
            max-height: 66px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .exa_detail {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 12px 0 15px 24px;
          }
        }
      }
      .nothing {
        padding-bottom: 60px;
      }
    }
  }
}
@media (min-width: 961px) {
  .example_list {
    .example_box {
      padding: 0;
      width: 1200px;
      margin: 0 auto;
      .example_title {
        font-size: 30px;
        line-height: 42px;
      }
      .example_ul {
        display: flex;
        flex-wrap: wrap;
        .example_every {
          margin-top: 24px;
          width: 384px;
          height: auto;
          margin-left: 24px;
          &:first-child {
            margin-top: 0;
          }
          &:nth-child(2) {
            margin-top: 0;
          }
          &:nth-child(3) {
            margin-top: 0;
          }
          &:nth-child(3n+1) {
            margin-left: 0;
          }
          .exa_link {
            display: inline-block;
            width: 100%;
            height: 100%;
            &:hover {
              box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
              .exa_img {
                transform: scale(1.1);
              }
              .exa_pad_right {
                .exa_title {
                  color: #0064EB;
                }
              }
            }
          }
          .exa_img_box {
            overflow: hidden;
          }
          .exa_img {
            width: 100%;
            height: 216px;
            transition: transform .5s;
          }
          .exa_title {
            font-size: 18px;
            line-height: 25px;
            padding: 24px 24px 12px 24px;
          }
          .exa_desc {
            font-size: 16px;
            line-height: 22px;
            padding: 0 24px;
            max-height: 66px;
            height: 66px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .exa_detail {
            padding: 24px 0 24px 0;
          }
        }
      }
    }
  }
}
</style>
